import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/Lenta"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { FormPKW } from "../components/FormPKW"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { MoreBenefitsRedisign } from "../components/MorBenefitsRedisgn"
import { FourAdvantagesPoints } from "../components/FourAdvantagesPoints"
import { BottomCta, RefinSeoBgImg } from "../components/BottomCta"
import { Partners } from "../components/PartnersNew"
import { Questions } from "../components/Questions"
import { NewFooter } from "../components/NewFooter"

import { getPageData } from "../helpers/getPageData"

import { PageData } from "../interfaces/pageProps"
import { QUESTIONS_FOR_LENTA } from "../components/Questions/helpers"
import { isKameleoonVariation } from "../utils/kameleoonCheckVariation"
import { lenta } from "../components/PartnersNew/helpers"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const bannerDescription = (
  <>
    — До 17% на остаток по карте
    <br />
    — Кэшбэк до 10%
    <br />— Обслуживание 0 ₽
  </>
)

const subTitlePartners =
  "Рассрочка 24 месяца в первый месяц использования Халвы – у всех партнеров. Со второго месяца срок рассрочки на новые покупки устанавливает партнер:"

const GIFT_TITLE = (
  <>
    Оформите Халву и получите
    <br />
    <b> месяц&nbsp;подписки&nbsp;в&nbsp;подарок</b>
  </>
)

// Время для таймер(30мин)
const countDownTime = 1800000

const longForm = isKameleoonVariation("21632667_halva_form_long", 874539)
export default function Page({ data }: PageProps<PageData>) {
  const bannerTitle = (
    <>
      <span>2000 баллов</span>
      <br />
      на покупку в «Ленте»
    </>
  )

  const { ligal, noIndex } = getPageData(data)

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader showHint={false} hasCTA additionalEventInDataLayer countDownTime={countDownTime} />

      <Banner orderNum="1" title={bannerTitle} description={bannerDescription} />

      <FourAdvantagesPoints
        withTitle
        indent
        variant="pkwRedesign"
        title="Преимущества с Халвой:"
        orderNum="2"
      />
      <FormPKW
        dataLayerName={longForm ? "longPersonalForm" : "shortPersonalForm"}
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        longForm={longForm}
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="3"
        onlyPKWPage
        isGreenApprove
        navigateVerifySMS={!longForm}
      />
      <HowInstallmentWorks
        headTitle="Как получить 2000 баллов на&nbsp;Карту№1*&nbsp;«Ленты»?"
        variant="lenta"
        orderNum="4"
      />
      <NewCalculator additionalEventInDataLayer orderNum="5" />
      <Partners
        title="Более 250&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="6"
        additionalPartners={lenta}
      />
      <MoreBenefitsRedisign
        additionalEventInDataLayer
        orderNum="7"
        hasSubtitle
        variant="pkwItemsNewHint"
        bottomBlock={false}
      />
      <BottomCta variant="pkwRedesign" title={GIFT_TITLE} BgImg={RefinSeoBgImg} orderNum="8" />
      <Questions questionList={QUESTIONS_FOR_LENTA} additionalEventInDataLayer orderNum="9" />
      <NewFooter ligal={ligal} orderNum="10" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/dachnii-sezon-v-lenta/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
